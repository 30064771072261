export interface KMenuWidget {
    backgroundColor?:        string;
    borderColor?:            string;
    color?:                  string;
    container?:              boolean;
    horizontalLinksPadding?: string;
    id:                      string;
    linksByLocale?:          LocaleLinksSchema[];
    linksPosition?:          LinksPosition;
    linksSize?:              string;
    menuColorOnHover?:       string;
    menuHeight?:             string;
}

export interface LocaleLinksSchema {
    links:  Link[];
    locale: Locale;
}

export interface Link {
    href?:         string;
    label:         string;
    linkType?:     LinkType;
    openInNewTab?: boolean;
}

export enum LinkType {
    Didomi = "DIDOMI",
    Folder = "FOLDER",
    Mail = "MAIL",
    URL = "URL",
}

export enum Locale {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    It = "it",
}

export enum LinksPosition {
    Center = "CENTER",
    Left = "LEFT",
    Right = "RIGHT",
}
