import { JsonConfig } from "../../../lib/commonPagePropsUtil";
import logger from "../../../lib/logger-utils";
import { Template } from "../../../models/configuration/definitions/template";
import { Type, Widget } from "../../../models/configuration/definitions/widget";
import { getAssociationsWidgetConfiguration } from "../AssociationsWidget/utils";
import { getEditoWidgetConfiguration } from "../EditoWidget/utils";
import { getFooterWidgetConfiguration } from "../KFooter/utils";
import { getKHeaderWidgetConfiguration } from "../KHeaderWidget/utils";
import { getKImageWidgetConfiguration } from "../KImageWidget/utils";
import { getMenuConfiguration } from "../KMenuWidget/utils";
import { getMosaicConfiguration } from "../KMosaicWidget/utils";
import { getNewsWidgetConfiguration } from "../KNewsWidget/utils";
import { getSearchWidgetConfiguration } from "../KSearchWidget/utils";
import { getSectionTitleWidgetConfiguration } from "../KSectionTitleWidget/utils";
import { getTabsWidgetConfiguration } from "../KTabsWidget/utils";
import { getKVideoWidgetConfiguration } from "../KVideoWidget/utils";
import { WidgetConfiguration, WidgetConfigurations } from "../models";
import { getSpacerWidgetConfiguration } from "../SpacerWidget/utils";
import { getWidgetKey } from "./getWidgetKey";
import { getWidgetsByTemplates } from "./getWidgetsByTemplates";

export const getWidgetConfiguration = ({
  widget,
  configSections,
}: {
  widget: Widget;
  configSections: JsonConfig[];
}): WidgetConfiguration | undefined => {
  switch (widget.type) {
    case Type.Mosaic:
      return getMosaicConfiguration(configSections, widget.id);
    case Type.Search:
      return getSearchWidgetConfiguration(configSections, widget.id);
    case Type.Footer:
      return getFooterWidgetConfiguration(configSections, widget.id);
    case Type.Attachments:
      return { type: widget.type };
    case Type.Sheet:
      return { type: widget.type };
    case Type.Custom:
      return { type: widget.type };
    case Type.Tabs:
      return getTabsWidgetConfiguration({ configSections, widgetId: widget.id });
    case Type.Image:
      return getKImageWidgetConfiguration(configSections, widget.id);
    case Type.Edito:
      return getEditoWidgetConfiguration(configSections, widget.id);
    case Type.Header:
      return getKHeaderWidgetConfiguration(configSections, widget.id);
    case Type.Associations:
      return getAssociationsWidgetConfiguration(configSections, widget.id);
    case Type.Spacer:
      return getSpacerWidgetConfiguration(configSections, widget.id);
    case Type.Video:
      return getKVideoWidgetConfiguration(configSections, widget.id);
    case Type.News:
      return getNewsWidgetConfiguration(configSections, widget.id);
    case Type.SectionTitle:
      return getSectionTitleWidgetConfiguration(configSections, widget.id);
    case Type.Menu:
      return getMenuConfiguration(configSections, widget.id);
    default:
      logger.error(`[getWidgetConfiguration] Widget of type ${widget.type} not implemented`);

      return undefined;
  }
};

export function getWidgetConfigurationsByTemplates({
  configSections,
  templates,
}: {
  configSections?: JsonConfig[];
  templates?: Template[];
}): WidgetConfigurations {
  const ret: WidgetConfigurations = {};
  if (!configSections || !templates) {
    return ret;
  }
  const widgets = getWidgetsByTemplates(templates);
  for (const widget of widgets) {
    const widgetKey = getWidgetKey(widget);
    const widgetConfig = getWidgetConfiguration({ widget, configSections });
    if (widgetKey && widgetConfig) {
      ret[widgetKey] = widgetConfig;
    }
  }
  return ret;
}
