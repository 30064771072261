import { useEffect, useMemo } from "react";

import {
  anticipatedRefreshState,
  FIRST_LOGIN_TYPE,
  KCConsumerStatus,
  keycloakInstanceAtom,
  useAuth,
  useAuthInit,
  useAuthRefresh,
  useStatsConnectionHit,
} from "@keepeek/commons";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import logger from "../../../lib/logger-utils";
import { useInitConfig } from "../../config/hooks/init";
import { frontEditState } from "../../frontEdit/atoms/frontEdit";
import { importModalOpenState } from "../../import/atoms";
import { useAuthProps } from "./useAuthProps";
import { useKeycloakConfig } from "./useKeycloakConfig";

type UseAuthProviderProps = {
  pathname: string;
  type?: FIRST_LOGIN_TYPE;
  unauthorizedCallback?: () => void;
  firstLoginCallback?: () => void;
};

export const useAuthProvider = ({
  pathname,
  unauthorizedCallback = () => {},
  firstLoginCallback = () => {},
  type = FIRST_LOGIN_TYPE.NONE,
}: UseAuthProviderProps) => {
  const { keycloakConfig, loading: loadingKeycloakConfig } = useKeycloakConfig();
  const keycloakInstance = useRecoilValue(keycloakInstanceAtom);
  useAuthInit(keycloakConfig, { disableSilentCheckSso: true });
  const { loading: loadingConfig, initConfig } = useInitConfig();
  const setAnticipatedRefresh = useSetRecoilState(anticipatedRefreshState);
  const { refresh } = useAuthRefresh();
  const {
    loading: loadingAuth,
    isAuthenticated: { isAuthenticated, loading: loadingIsAuthenticated },
  } = useAuth(useAuthProps());

  const isSecuredPath = useMemo(() => {
    if (
      [PagesPath.ELEMENT_PAGE, PagesPath.HOME_PAGE, PagesPath.SEARCH_PAGE].includes(
        pathname as PagesPath,
      ) ||
      (pathname.startsWith(PagesPath.ADMIN) && !pathname.includes(PagesPath.ONBOARDING))
    ) {
      logger.debug("useAuthProvider - isSecuredPath", true);
      return true;
    }
    logger.debug("useAuthProvider - isSecuredPath", false);
    return false;
  }, [pathname]);

  const loading: boolean = useMemo(
    () =>
      (loadingKeycloakConfig ||
        loadingConfig ||
        !keycloakConfig ||
        loadingAuth ||
        loadingIsAuthenticated) &&
      !isAuthenticated &&
      pathname !== PagesPath.FIRST_LOGIN,
    [
      loadingKeycloakConfig,
      loadingConfig,
      keycloakConfig,
      loadingAuth,
      loadingIsAuthenticated,
      isAuthenticated,
      pathname,
    ],
  );

  const status: KCConsumerStatus = useMemo(() => {
    if (isSecuredPath) {
      if (isAuthenticated) {
        logger.debug("useAuthProvider - status", KCConsumerStatus.Authorized);
        return KCConsumerStatus.Authorized;
      }
      if (loading) {
        logger.debug("useAuthProvider - status", KCConsumerStatus.Loading);
        return KCConsumerStatus.Loading;
      }
      logger.debug("useAuthProvider - status", KCConsumerStatus.Unauthorized);
      return KCConsumerStatus.Unauthorized;
    }
    logger.debug("useAuthProvider - status", KCConsumerStatus.Authorized);
    return KCConsumerStatus.Authorized;
  }, [isAuthenticated, isSecuredPath, loading]);

  useEffect(() => {
    if (status === KCConsumerStatus.Unauthorized) {
      logger.debug("useAuthProvider - setAnticipatedRefresh", false);
      setAnticipatedRefresh(false);
      logger.debug("useAuthProvider - unauthorizedCallback");
      unauthorizedCallback();
    }
  }, [status, unauthorizedCallback, setAnticipatedRefresh, pathname]);

  useEffect(() => {
    if (
      type !== FIRST_LOGIN_TYPE.NONE &&
      type !== FIRST_LOGIN_TYPE.PASS &&
      pathname !== PagesPath.FIRST_LOGIN
    ) {
      logger.debug("useAuthProvider - firstLoginCallback");
      firstLoginCallback();
    }
  }, [firstLoginCallback, type, pathname]);

  const isImporting = useRecoilValue(importModalOpenState);
  const isInFrontEditMode = useRecoilValue(frontEditState);

  useEffect(() => {
    if (
      ((pathname.startsWith(PagesPath.ADMIN) && !pathname.includes(PagesPath.ONBOARDING)) ||
        isInFrontEditMode ||
        isImporting) &&
      status === KCConsumerStatus.Authorized
    ) {
      logger.debug("useAuthProvider - setAnticipatedRefresh - refresh");
      refresh();
      logger.debug("useAuthProvider - setAnticipatedRefresh", true);
      setAnticipatedRefresh(true);
    } else {
      logger.debug("useAuthProvider - setAnticipatedRefresh", false);
      setAnticipatedRefresh(false);
    }
  }, [pathname, setAnticipatedRefresh, isInFrontEditMode, isImporting, refresh, status]);

  const { sendConnectionHit, sending, sent } = useStatsConnectionHit({ ip: initConfig?.clientIp });

  useEffect(() => {
    // If we have a sessionId try to send the connection hit
    // The sendConnectionHit will resolve automatically if we need to send it or not
    if (keycloakInstance?.sessionId && !sending && !sent) {
      logger.debug("useAuthProvider - sendConnectionHit");
      sendConnectionHit();
    }
  }, [keycloakInstance?.sessionId, sendConnectionHit, sending, sent]);

  return { loading, status, isSecuredPath };
};
