export interface Widget {
    classNames?: string;
    id:          string;
    type:        Type;
}

export enum Type {
    Associations = "associations",
    Attachments = "attachments",
    Custom = "custom",
    Edito = "edito",
    Footer = "footer",
    Header = "header",
    Image = "image",
    Menu = "menu",
    Mosaic = "mosaic",
    News = "news",
    Search = "search",
    SectionTitle = "sectionTitle",
    Sheet = "sheet",
    Spacer = "spacer",
    Tabs = "tabs",
    Video = "video",
}
