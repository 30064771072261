import { KpkFilterValue } from "@keepeek/commons";

export function removeCountFromValues(values: KpkFilterValue[]): KpkFilterValue[] {
  return values.map((v) => {
    return {
      ...v,
      label: v?.label?.split(" (")?.[0],
      title: v?.title?.split(" (")?.[0],
    };
  });
}
