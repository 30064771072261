export interface Element {
    actionButtons?:           ActionButtons;
    activateRMM?:             boolean;
    background?:              Background;
    elementId?:               ElementID;
    fieldsSectionsToDisplay?: FieldsSections;
    headerFunctionalFields?:  HeaderFunctionalFields;
    headerSideField?:         HeaderSideField;
    headerTechnicalFields?:   HeaderFunctionalFields;
    mode:                     Mode;
    sections?:                SectionSchema[];
    showAttachments?:         boolean;
    templates?:               TemplateSchema[];
    templatesFooter?:         TemplateSchema[];
    templatesLayout?:         TemplateSchema[];
    visualAlerts?:            VisualAlertsSchema[];
}

export interface ActionButtons {
    main?: string;
}

export interface Background {
    dark?: string;
    main?: string;
}

export interface ElementID {
    extension?:  boolean;
    identifier?: string;
}

export interface FieldsSections {
    backofficeConfiguration?: boolean;
    default?:                 FieldsSectionsItemSchema[];
    metamodel?:               FieldsSectionsToDisplayMetamodel[];
    metamodelType?:           FieldsSectionsToDisplayMetamodelType[];
}

export interface FieldsSectionsItemSchema {
    type:  DefaultType;
    value: any;
}

export enum DefaultType {
    Field = "field",
    Section = "section",
}

export interface FieldsSectionsToDisplayMetamodel {
    fieldsSections: FieldsSectionsItemSchema[];
    metamodelId:    number[];
}

export interface FieldsSectionsToDisplayMetamodelType {
    fieldsSections: FieldsSectionsItemSchema[];
    metamodelType:  MetamodelTypeEnum[];
}

export enum MetamodelTypeEnum {
    Document = "DOCUMENT",
    Other = "OTHER",
    Picture = "PICTURE",
    Sound = "SOUND",
    Video = "VIDEO",
}

export interface HeaderFunctionalFields {
    default?:       string[];
    metamodel?:     HeaderFunctionalFieldsMetamodel[];
    metamodelType?: HeaderFunctionalFieldsMetamodelType[];
}

export interface HeaderFunctionalFieldsMetamodel {
    fields:      string[];
    metamodelId: number[];
}

export interface HeaderFunctionalFieldsMetamodelType {
    fields:        string[];
    metamodelType: MetamodelTypeEnum[];
}

export interface HeaderSideField {
    default?:       string;
    metamodel?:     HeaderSideFieldMetamodel[];
    metamodelType?: HeaderSideFieldMetamodelType[];
}

export interface HeaderSideFieldMetamodel {
    field:       string;
    metamodelId: number[];
}

export interface HeaderSideFieldMetamodelType {
    field:         string;
    metamodelType: MetamodelTypeEnum[];
}

export enum Mode {
    Fixed = "fixed",
    Scroll = "scroll",
}

export interface SectionSchema {
    id:          string;
    translation: TranslationSchema[];
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    It = "it",
}

export interface TemplateSchema {
    animation?:            Animation;
    backgroundColor?:      string;
    classNames?:           string;
    columns?:              Column[];
    columnSpacing?:        number;
    container?:            boolean;
    gridSpacing?:          number;
    itemTitle?:            string;
    margin?:               number;
    padding?:              number;
    rowSpacing?:           number;
    verticallyExtendable?: boolean;
}

export interface Animation {
    animationType?: AnimationType;
}

export enum AnimationType {
    FadeInBottom = "FADE_IN_BOTTOM",
    NoAnimation = "NO_ANIMATION",
}

export interface Column {
    backgroundColor?: string;
    borderColor?:     string;
    borderWidth?:     number;
    breakpoints?:     Breakpoint;
    classNames?:      string;
    itemTitle?:       string;
    widgets?:         WidgetSchema[];
}

export interface Breakpoint {
    lg?: number;
    md?: number;
    sm?: number;
    xl?: number;
    xs?: number;
}

export interface WidgetSchema {
    classNames?: string;
    id:          string;
    type:        WidgetType;
}

export enum WidgetType {
    Associations = "associations",
    Attachments = "attachments",
    Custom = "custom",
    Edito = "edito",
    Footer = "footer",
    Header = "header",
    Image = "image",
    Menu = "menu",
    Mosaic = "mosaic",
    News = "news",
    Search = "search",
    SectionTitle = "sectionTitle",
    Sheet = "sheet",
    Spacer = "spacer",
    Tabs = "tabs",
    Video = "video",
}

export interface VisualAlertsSchema {
    backgroundColor?:   string;
    borderRadius?:      number;
    color?:             string;
    dateTargetMode:     DateTargetMode;
    dateTargetNumber:   number;
    dateTargetUnit:     DateTargetUnit;
    displayFieldValue?: boolean;
    fieldInternalName:  string;
    fieldType:          FieldType;
    fontSize?:          number;
    marginBottom?:      number;
    marginLeft?:        number;
    marginRight?:       number;
    marginTop?:         number;
    padding?:           number;
    position:           Position;
    subStatusId?:       number;
    text:               TranslationSchema[];
}

export enum DateTargetMode {
    ExpireWithin = "EXPIRE_WITHIN",
    ExpiredSince = "EXPIRED_SINCE",
    ExpiredSinceLessThan = "EXPIRED_SINCE_LESS_THAN",
}

export enum DateTargetUnit {
    Days = "DAYS",
    Months = "MONTHS",
    Years = "YEARS",
}

export enum FieldType {
    Date = "DATE",
    SubStatus = "SUB_STATUS",
}

export enum Position {
    BottomLeft = "BOTTOM_LEFT",
    BottomRight = "BOTTOM_RIGHT",
    TopLeft = "TOP_LEFT",
    TopRight = "TOP_RIGHT",
}
