import React, { FC } from "react";

import { useShareToken } from "@keepeek/commons";
import { useCurrentUser } from "@keepeek/commons";
import { KActionButton, KButton } from "@keepeek/refront-components";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { Dataview } from "../../../models/configuration/components/dataview";
import { useConfiguration } from "../../../providers/config/hooks";
import { KIcon } from "../../common/KIcon";
import { CONFIGURATION_SECTION_PATH } from "../../layout/adminMenu";

export enum ActionValue {
  DOWNLOAD = "download",
  DOWNLOAD_CONTACT_SHEET = "downloadContactSheet",
  CONTACT_SHEETS = "contactSheets",
  ADD_TO_BASKET = "addSelectionToBasket",
  OPEN_ELEMENT = "openElement",
  DELETE_BASKET = "deleteBasket",
  EDIT_BASKET = "editBasket",
  REMOVE_FROM_BASKET = "removeFromBasket",
  CLEAR_BASKET = "clearBasket",
  SELECT_ALL = "selectAll",
  UNSELECT_ALL = "unselectAll",
  SHARE = "share",
  SHOW_ATTACHMENT = "showAttachment",
}

export const CURRENT_PAGE_SELECTION_IDENTIFIER: number = -1;

export enum ActionDisplayType {
  MENU = "menu",
  BUTTON = "button",
  ACTION_BUTTON = "action_button",
}

type ActionsProps = {
  displayType: ActionDisplayType;
  isSelection: boolean;
  isBasket?: boolean;
  isRichElement?: boolean;
  onActionClick: (value: ActionValue) => void;
  onActionMouseEnter?: (value: ActionValue) => void;
  selectedElements: Array<number>;
};

type Action = {
  labelKey: string;
  testId?: string;
  icon: string;
  value: ActionValue;
  showForBasket: boolean;
  showForShare: boolean;
  showForOneElement: boolean;
  showForManyElement: boolean;
  hidden?: boolean;
};
const actionList: Array<Action> = [
  {
    labelKey: "dataview.action.download.label",
    testId: "downloadSelectionButton",
    icon: "cloud_download",
    value: ActionValue.DOWNLOAD,
    showForBasket: true,
    showForShare: true,
    showForOneElement: true,
    showForManyElement: true,
  },
  {
    labelKey: "dataview.action.add-to-basket.label",
    testId: "addToBasketButton",
    icon: "shopping_basket",
    value: ActionValue.ADD_TO_BASKET,
    showForBasket: false,
    showForShare: false,
    showForOneElement: false,
    showForManyElement: true,
  },
  {
    labelKey: "dataview.action.remove-from-basket.label",
    testId: "removeFromBasketButton",
    icon: "clear",
    value: ActionValue.REMOVE_FROM_BASKET,
    showForBasket: true,
    showForShare: false,
    showForOneElement: false,
    showForManyElement: false,
  },
];

function isActionVisible(
  isSelection: boolean,
  isBasket: boolean = false,
  isShare: boolean = false,
  action: Action,
  shareTokenDownloadAuthorized?: boolean,
  isRichElement: boolean = false,
): boolean {
  if (action.hidden) {
    return false;
  }

  if (isRichElement && action.value === ActionValue.DOWNLOAD) {
    return false;
  }

  if (isShare && (!action.showForShare || !shareTokenDownloadAuthorized)) {
    return false;
  }
  return (
    (!isBasket &&
      ((isSelection && action.showForManyElement) || (!isSelection && action.showForOneElement))) ||
    (isBasket && action.showForBasket)
  );
}

type DisplayProps = {
  enabled: boolean;
} & ActionsProps;

const ButtonDisplay: FC<React.PropsWithChildren<DisplayProps>> = function ButtonDisplay({
  onActionClick,
  onActionMouseEnter = () => {},
  isSelection,
  isBasket,
  selectedElements,
  enabled,
}) {
  const router = useRouter();
  const { isShare, shareTokenDownloadAuthorized } = useShareToken(
    router.pathname,
    router.query.tokenId,
  );
  const { t } = useTranslation();

  const dataViewConfig = useConfiguration<Dataview>(CONFIGURATION_SECTION_PATH.COMPONENTS_DATAVIEW);
  const { hasContactSheets } = useCurrentUser();
  const contactSheetsEnabled = dataViewConfig?.contactSheets && hasContactSheets;

  const contactSheetActionButton: Action = {
    labelKey: "dataview.action.contact-sheets.label",
    testId: "contactSheetsButton",
    icon: "picture_as_pdf",
    value: ActionValue.CONTACT_SHEETS,
    showForBasket: true,
    showForShare: false,
    showForOneElement: true,
    showForManyElement: true,
    hidden: !contactSheetsEnabled,
  };

  const completeActionList: Array<Action> = [...actionList, contactSheetActionButton];

  return (
    <>
      {completeActionList.map(
        (action) =>
          isActionVisible(isSelection, isBasket, isShare, action, shareTokenDownloadAuthorized) && (
            <KButton
              key={action.labelKey}
              testId={action.testId}
              variant={enabled ? "outlined" : "text"}
              color="primary"
              size="small"
              sx={{
                marginRight: 1,
                padding: (theme) => theme.spacing(0.5, 1),
              }}
              startIcon={<KIcon>{action.icon}</KIcon>}
              buttonName={action.value}
              disabled={!enabled}
              onClick={() => {
                onActionClick(action.value);
              }}
              onMouseEnter={() => onActionMouseEnter(action.value)}
            >
              {t(action.labelKey, { count: selectedElements.length })}
            </KButton>
          ),
      )}
    </>
  );
};

const MenuDisplay: FC<React.PropsWithChildren<DisplayProps>> = function MenuDisplay({
  onActionClick,
  onActionMouseEnter = () => {},
  isSelection,
  isBasket,
  selectedElements,
  enabled,
}) {
  const router = useRouter();
  const { isShare, shareTokenDownloadAuthorized } = useShareToken(
    router.pathname,
    router.query.tokenId,
  );
  const { t } = useTranslation();

  return (
    <>
      {actionList.map(
        (action) =>
          isActionVisible(isSelection, isBasket, isShare, action, shareTokenDownloadAuthorized) && (
            <MenuItem
              key={action.labelKey}
              disabled={!enabled}
              onClick={() => {
                onActionClick(action.value);
              }}
              onMouseEnter={() => onActionMouseEnter(action.value)}
            >
              <ListItemIcon>
                <KIcon>{action.icon}</KIcon>
              </ListItemIcon>
              <ListItemText primary={t(action.labelKey, { count: selectedElements.length })} />
            </MenuItem>
          ),
      )}
    </>
  );
};

const ActionButtonDisplay: FC<React.PropsWithChildren<DisplayProps>> =
  function ActionButtonDisplay({
    onActionClick,
    onActionMouseEnter = () => {},
    isSelection,
    isBasket,
    isRichElement,
    selectedElements,
    enabled,
  }) {
    const router = useRouter();
    const { isShare, shareTokenDownloadAuthorized } = useShareToken(
      router.pathname,
      router.query.tokenId,
    );
    const { t } = useTranslation();

    return (
      <>
        {actionList.map(
          (action) =>
            isActionVisible(
              isSelection,
              isBasket,
              isShare,
              action,
              shareTokenDownloadAuthorized,
              isRichElement,
            ) && (
              <KActionButton
                key={action.labelKey}
                disabled={!enabled}
                onClick={() => {
                  onActionClick(action.value);
                }}
                onMouseEnter={() => onActionMouseEnter(action.value)}
                actionButtonName={action.value}
                aria-label={t(action.labelKey, { count: selectedElements.length }) as string}
                title={t(action.labelKey)}
              >
                <KIcon fontSize="small">{action.icon}</KIcon>
              </KActionButton>
            ),
        )}
      </>
    );
  };

const Actions: FC<React.PropsWithChildren<ActionsProps>> = function Actions(props) {
  const enabled = !props.isSelection || props.selectedElements.length > 0;

  switch (props.displayType) {
    case ActionDisplayType.BUTTON:
      return <ButtonDisplay {...props} enabled={enabled} />;
    case ActionDisplayType.MENU:
      return <MenuDisplay {...props} enabled={enabled} />;
    case ActionDisplayType.ACTION_BUTTON:
      return <ActionButtonDisplay {...props} enabled={enabled} />;
    default:
      throw new Error(`this display type ${props.displayType} is not implemented`);
  }
};
export default Actions;
