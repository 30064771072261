export interface KImageWidget {
    backgroundColor?:      string;
    id:                    string;
    kImageWidgetimage?:    KImage;
    kImageWidgetposition?: KImageWidgetposition;
    kImageWidgetUrl?:      KImageWidgetURL;
    shareWithConfig?:      boolean;
}

export interface KImageWidgetURL {
    folderId?:    number;
    href?:        string;
    linkType?:    LinkType;
    targetBlank?: boolean;
    title?:       string;
}

export enum LinkType {
    Didomi = "DIDOMI",
    Folder = "FOLDER",
    Mail = "MAIL",
    URL = "URL",
}

export interface KImage {
    alt?:          TranslationSchema[];
    animation?:    Animation;
    borderRadius?: string;
    minHeight?:    string;
    url?:          TranslationResourceSchema[];
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    It = "it",
}

export interface Animation {
    animationType?: AnimationType;
}

export enum AnimationType {
    NoAnimation = "NO_ANIMATION",
    ZoomOnHover = "ZOOM_ON_HOVER",
}

export interface TranslationResourceSchema {
    language:  Language;
    resource?: Resource;
    value?:    string;
}

export interface Resource {
    id?:  string;
    url?: string;
}

export interface KImageWidgetposition {
    cover?:      boolean;
    horizontal?: Horizontal;
    vertical?:   Vertical;
}

export enum Horizontal {
    Center = "center",
    Left = "left",
    Right = "right",
}

export enum Vertical {
    Bottom = "bottom",
    Center = "center",
    Top = "top",
}
